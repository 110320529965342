"use client";
import Image from "next/image";
import Link from "next/link";
import PropTypes from "prop-types";
import parser from "html-react-parser";

export default function NotFound({ content = {} }) {
  const buttons = [];
  if (typeof content?.buttons === typeof [] && content?.buttons?.length > 0) {
    content?.buttons?.map(({ id, text, link, isDisabled }) => {
      buttons.push(
        <Link
          key={id}
          className="btn btn--primary bold-iq"
          href={link}
          onClick={(e) => {
            if (isDisabled) {
              e.preventDefault();
              return;
            }
          }}
        >
          {text}
        </Link>
      );
    });
  }
  return (
    <section className="not-found">
      {content?.imageDesktop ? null : (
        <div className="not-found__img not-found__img--desktop">
          <Image
            src={content?.imageDesktop || ""}
            alt="404"
            style={{ width: "auto", height: "auto" }}
            width={248}
            height={274}
            loading="lazy"
          />
        </div>
      )}
      {content?.imageMobile ? null : (
        <div className="not-found__img not-found__img--mobile">
          <Image
            src={content?.imageMobile || ""}
            alt="404"
            style={{ width: "auto", height: "auto" }}
            width={406}
            height={426}
            loading="lazy"
          />
        </div>
      )}
      <div className="not-found__text">
        {parser(content?.title || "")}
        {parser(content?.content || "")}
        <div className="not-found__buttons bold-iq">{buttons}</div>
      </div>
    </section>
  );
}

NotFound.propTypes = {
  /**
   * Content
   */
  content: PropTypes.object.isRequired,
};
